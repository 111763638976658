import { graphql, PageProps } from "gatsby";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import NotificationBar from "../../components/notification-bar";
import Seo from "../../components/seo";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import GlobalContext from "../../contexts/global-provider";
import { PackageProvider } from "../../contexts/package-provider";
import { TideItemForCountry, TideItemForUspFolder, TideItemForWebsite, TideItemForWildcardCountryPage } from "../../types";
import { parse } from "../../utils/component";
import MapCountryPageComponents from "../../utils/mappers/map-country-page-components";

export interface CountryPageTemplateData {
  website: TideItemForWebsite;
  page: TideItemForCountry;
  usps: TideItemForUspFolder;
  wildcardCountryPage: TideItemForWildcardCountryPage;
}

interface CountryPageTemplateContext {
  language: string;
  breadcrumbs: Breadcrumb[];
}

const CountryPageTemplate: React.FC<PageProps<CountryPageTemplateData, CountryPageTemplateContext>> = ({
  data,
  pageContext: { language, breadcrumbs },
}) => {
  const globalContext = useContext(GlobalContext);
  const [countryId, setCountryId] = useState<number>();
  const [cancelPackagesSearch, setCancelPackagesSearch] = useState<boolean>(false);
  const { games, notificationBar, primaryNavigation, setGlobalData } = useContext<ComponentContextType>(ComponentContext);

  useEffect(() => {
    (async () => {
      if (globalContext && globalContext.language !== language) {
        globalContext.setLanguage(language);
      }
      if (!games || games.length == 0) {
        await setGlobalData();
      }
      if (data.page.content?.general?.country?.tideId) {
        setCountryId(data.page.content?.general?.country?.tideId);
      } else {
        setCancelPackagesSearch(true);
      }
    })();
  }, []);

  return (
    <>
      {primaryNavigation && (
        <Layout>
          <Seo
            title={parse(data.page.content?.seo?.seoTitle)}
            description={parse(data.page.content?.seo?.seoDescription)}
            keywords={parse(data.page.content?.seo?.seoKeywords)}
            website={parse(data.website)}
            breadcrumbs={parse(breadcrumbs)}
          />
          {!isEmpty(notificationBar?.content?.general?.notification) && (
            <NotificationBar
              text={notificationBar?.content?.general?.notification ?? ""}
              icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
            />
          )}
          <Navbar website={parse(data.website)} />
          {globalContext.language === language && games.length > 0 && (
            <PackageProvider countryId={countryId} cancelPackagesSearch={cancelPackagesSearch}>
              <MapCountryPageComponents
                website={data.website}
                page={data.page}
                usps={data.usps}
                breadcrumbs={breadcrumbs}
                wildcardCountryPage={data.wildcardCountryPage}
              />
            </PackageProvider>
          )}
          <Footer name={parse(data.website.name)} logo={parse(data.website.content?.general?.logo)} />
        </Layout>
      )}
    </>
  );
};

export default CountryPageTemplate;

export const query = graphql`
  query CountryPageQuery($pageId: String, $websiteId: String) {
    wildcardCountryPage: tideItemForWildcardCountryPage(parentItem: { id: { eq: $websiteId } }) {
      __typename
      id
      name
      childItems {
        ...TideItemForHeroComponentFragment
        ...TideItemForPageTitleComponentFragment
        ...TideItemForIntroComponentFragment
        ...TideItemForOverviewComponentFragment
        ...TideItemForInfoBlocksComponentFragment
      }
    }
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      name
      content {
        general {
          phone
          helptext
          linkSgr
          linkThuiswinkelWaarborg
          linkTelephone
          logo {
            altText
            url
            title
          }
          linkLogo
        }
      }
    }
    page: tideItemForCountry(id: { eq: $pageId }) {
      name
      id
      content {
        general {
          hero {
            altText
            title
            url
          }
          country {
            tideId
          }
          countryId
          intro
          title
          path
          flag {
            altText
            title
            url
          }
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
      childItems {
        ...BlocksFragment
        ...TideItemForQuickLinksSectionFragment
      }
    }
    usps: tideItemForUspFolder(parentItem: { id: { eq: $websiteId } }) {
      name
      childItems {
        ... on TideItemForUspItem {
          __typename
          id
          name
          content {
            general {
              fai
              text
              title
            }
          }
        }
      }
    }
  }
`;
